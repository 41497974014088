<template>
  <div id="app" >
     
    <div class="container-fluid">
      <div class="row">
        <div class="col padding_none">
          <div class="content_wrap">
            <router-view/>
             
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
// import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'
  

export default {
  data () {
      return {
        quantity: 0,
      }
    },
  name: 'app',
  methods: {
      ...Vuex.mapActions({
        check: 'auth/check',
      }),
  },
  created: function () {
    if (this.isAuthenticated) {
      this.check()
    }
    
  }
}
</script>

<style lang="scss" >
 @import './style/scss/mine';
</style>


