export default {
  methods: {
    toString(error) {
      if (error == null) {
        return ''
      }
      var result = ''
      if( typeof error == 'object'){
        for (var key in error) {
          if (error[key] != null && error[key] != 'null' && error[key] != '') {
            result += " " + error[key]
          }
        }
      }
      if (result == '') {
          result = error
      }
      return result
      },
    }
  }