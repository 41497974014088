/**
* Created by vouill on 11/13/17.
*/

<template>
  <div class="login_form">
    <div class="container h100">
      <div class="row justify-content-md-center">
        <div class="col-md-auto">

          <section id="s1" class="login_page"  style="margin-top:30%">
            <div class="inner">
              <div class="wrap">
                <img src="/img/logo_techcon.png" alt="">
                <h1>Авторизация</h1>
                <div v-if="message" class="alert alert-danger">
                    {{ message }}
                </div>
                <form action="" class="login" @submit.prevent="auth">
                  <h4>Ваш логин</h4>
                  <input required v-model="login" id="login" type="text" placeholder="Введите логин" >
                  <h4>Ваш пароль</h4>
                  <input required v-model="password" id = "password"  type="password" placeholder="Введите пароль">
                  <br>

                  <input type="submit" id="submit" value="Войти в систему">
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'auth',
    data () {
      return {
        login: '',
        password: '',
      }
    },
    computed: {
      ...mapGetters(['isAuthenticated']),
      message () {
        return this.$store.state.auth.message
      }
    },
    methods: {
      auth: function () {
        const { login, password } = this
        this.$store.dispatch('auth/auth', { login, password }).then(() => {
           this.$router.push('/')
        })
      }
    },
  }
</script>
<style scoped>
 .h100{
   min-height: 100vh;
 }
</style>
