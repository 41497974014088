<template>
  <section class="container">
      <div class="sticky col-6 offset-3">
        <handler-errors :errorMes="errorMes"/>
         <modal-message v-if="visibleModalMes == true"/>
      </div>
    <div class="row items pasport">
        <button class="btn butDef" @click="cancel()" >Отмена</button>
      <div class="col-12">
        <div class="iten">
          <h2>Информация об клиенте</h2>
          <div class="row pas_item">
              <div class="input_title">Тип аккаунта</div>
              <select   v-model="newClient.type">
                <option :value="30">Клиент</option>
                <option :value="20">Дистрибьютор</option>
              </select>
          </div>
          <div class="row pas_item">
              <div class="input_title">Название</div>
              <input :class="notNull(newClient.name) ? 'succes' : 'wron'"  v-model="newClient.name"/>
          </div>
          <div class="row pas_item">
              <div class="input_title">Адрес</div>
              <input :class="notNull(newClient.address) ? 'succes' : 'wron'"  v-model="newClient.address"/>
          </div>
          <div class="row pas_item">
              <div class="input_title">Директор</div>
              <input v-model="newClient.ceo"/>
          </div>
          <div class="row pas_item">
              <div class="input_title">Инженер</div>
              <input v-model="newClient.engeneer"/>
          </div>
          <div class="row pas_item">
              <div class="input_title">ИНН</div>
              <input :class="validateInn(newClient.inn) ? 'succes' : 'wron'"  v-model="newClient.inn"/>
          </div>
          <div class="row pas_item">
              <div class="input_title">КПП</div>
              <input :class="notNull(newClient.kpp) ? 'succes' : 'wron'"  v-model="newClient.kpp"/>
          </div>
          <div class="row pas_item">
              <div class="input_title">Банк</div>
              <input v-model="newClient.bank"/>
          </div>
          <div class="row pas_item">
              <div class="input_title">БИК</div>
              <input v-model="newClient.bik"/>
          </div>
          <div class="row pas_item">
              <div class="input_title">ОГРН</div>
              <input :class="onlyNumbersOgrn() ? 'succes' : 'wron'" v-model="newClient.ogrn"/> 
          </div>
          <div class="row ">
              <div class="input_title">Телефон</div>
              <the-mask :class="onlyNumbers() ? 'succes' : 'wron'" v-model="newClient.phone" mask="+7 (###) ###-####"  type="tel" />
          </div>
          <div class="row pas_item">
              <div class="input_title">Система налогооблажения</div>
              <select  v-model="newClient.tax_type_id">
                <option :value="10">С НДС</option>
                <option :value="20">БЕЗ НДС</option>
              </select>
          </div>
          <div class="row pas_item">
              <div class="input_title">Расчетный счет</div>
              <input v-model="newClient.ras_schet"/>
          </div>
          <div class="row pas_item">
              <div class="input_title">Корреспондентский счет</div>
              <input v-model="newClient.cor_schet"/>
          </div>
          <div class="row pas_item">
              <div class="input_title">Главный бухгалтер</div>
              <input v-model="newClient.booker"/>
          </div>
          <div class="row pas_item">
              <div class="input_title">Контактное лицо</div>
              <input v-model="newClient.contact"/>
          </div>
          <div class="row pas_item">
              <div class="input_title">Email пользователя</div>
              <input :class="isEmailValidUser() ? 'succes' : 'wron'" v-model="newClient.user_email"/>
          </div>
          <div class="row pas_item">
              <div class="input_title">Email аккаунта</div>
              <input v-model="newClient.account_email"/>
          </div>
          <div class="row pas_item">
              <button class="btn butDef" style="margin-top:10px; float: right; right: 0px; " @click="add()">Добавить</button>{{maessage}}
          </div>
           
        </div>
      </div>
    </div>
      
  </section>
</template>
<script>

import Vue from 'vue'
import Vuex from 'vuex'
import HandlerErrors from '../../src/utils/handlerErrors.vue'
import VueTheMask from 'vue-the-mask'
import ModalMessage from './modals/alertAddClient.vue'
Vue.use(VueTheMask)

  export default {
    data () {
      return {
          errorMes: '',
          error:{
              code: '', message: '',
          },
          reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
          newClient:{
            account_email: "",
            address: "",
            bank: "",
            bik: "",
            booker: "",
            ceo: "",
            contact: "",
            cor_schet: "",
            engeneer: "",
            inn: "",
            kpp: "",
            name: "",
            ogrn: "",
            phone: "",
            ras_schet: "",
            tax_type_id: 0,
            type: 30,
            user_email: ""
          }
      }
    },
    components: {
      ModalMessage,
                HandlerErrors},
    computed: {
      ...Vuex.mapGetters({
          maessage:'accounts/maessage',
            visibleModalMes: 'accounts/visibleModalMes',
        }),
    },
    methods: {
      ...Vuex.mapActions({
          addCompany:'accounts/addCompany'
      }),
      ...Vuex.mapMutations({
          setMessage:'accounts/setMessage',
           setVisibleModalMes:'accounts/setVisibleModalMes'
      }),
      cancel(){
           this.$router.push('/')
      },
      add(){
          var client = this.newClient
        //   client.tax_type_id = +client.tax_type_id
          this.addCompany(client)
            .then((resp)=>{
                this.setVisibleModalMes(true)
                this.errorMes = resp
            })
            .catch((error) => {
                this.errorMes = error.response.data.error.detail[0]
          })
      },
       onlyNumbers() {
            return !/^[\d]{10}$/.test(this.newClient.phone) ? false : true
        },
        onlyNumbersOgrn() {
            return !/^[\d]{13}$/.test(this.newClient.ogrn) ? false : true
        },
       isEmailValid() {
            return (this.newClient.account_email == "")? "" : (this.reg.test(this.newClient.account_email)) ? true : false
        },
        isEmailValidUser() {
            return (this.newClient.user_email == "")? "" : (this.reg.test(this.newClient.user_email)) ? true : false
        },
        notNull(value){
            var val = false
            if(value === ''){
                val = false
            }else{
                val = true
            }
            return val
            // return (this.profile.fullName.length <= 0)? "" : (this.profile.fullName.length >= 1 )? true : false
        },
        validateInn(inn) {
            var result = false
            if (typeof inn === 'number') {
                inn = inn.toString();
            } else if (typeof inn !== 'string') {
                inn = '';
            }
            if (!inn.length) {
                this.error.code = 1;
                this.error.message = 'ИНН пуст';
            } else if (/[^0-9]/.test(inn)) {
                this.error.code = 2;
                this.error.message = 'ИНН может состоять только из цифр';
            } else if ([10, 12].indexOf(inn.length) === -1) {
                this.error.code = 3;
                this.error.message = 'ИНН может состоять только из 10 или 12 цифр';
            } else {
                var checkDigit = function (inn, coefficients) {
                    var n = 0;
                    for (var i in coefficients) {
                        n += coefficients[i] * inn[i];
                    }
                    return parseInt(n % 11 % 10);
                };
                switch (inn.length) {
                    case 10:
                        var n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
                        if (n10 === parseInt(inn[9])) {
                            result = true;
                        }
                        break;
                    case 12:
                        var n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
                        var n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
                        if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
                            result = true;
                        }
                        break;
                }
                if (!result) {
                    this.error.code = 4;
                    this.error.message = 'Неправильное контрольное число';
                }
            }
            return result;
        },
    },
    created () {
    },
  }
</script>
<style scoped>
  label.fl {
    display: block;
    cursor: pointer;
  }

  label.fl::before {
    display: inline-block;
    padding-right: 5px;
    vertical-align: top;
    /* content: url(/img/camera.png); */
  }

  label [type=file] {
    display: none;
  }
  input:focus{
     border: 2px solid #b7ddec;
  }
  .iten input:active{
      border: none;
  }
  .iten .wron{
      background-color: rgba(248, 104, 111, 0.3);
    }
    .iten .succes{
    
      background-color: rgba(152, 248, 104, 0.3);
    }
</style>
