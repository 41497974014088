<template>
    <div class="container-fluid items pasport">
        <div class="">
        <button class="btn butDef" @click="exit()">
              Выйти из аккаунта
        </button>
        <h1 class="main__heading">
              {{user.email}}
        </h1> 
        <div class=" pas_item acc__block">
            <input class="" style="width: 100%;" type="text" v-model="filterInput" placeholder="Искать здесь...">
            <!-- <button>
             <i class="fa fa-search"></i>
            </button> -->
        </div>
        <h3 class="acc__block" v-if="user.account != null">
            Баланс:
              {{user.account.balance}}
        </h3> 
        <button v-if="user.account.type != 30" class="acc__block btn butDef" @click="addClient()" >Добавить аккаунт</button>
         <div style=" float: right; right: 0px; ">
            <button class="btn butDef" :disabled="this.pageNumber <= 1 " @click="prevPage">
              <i class="fas fa-angle-left"></i>
            </button>
            <button class="btn butDef" :disabled="pageNumber >= pageCount"   @click="nextPage">
              <i class="fas fa-angle-right"></i>
            </button>
        </div>
      <div class="d-table">
            <div class="d-tr table__heading" >
                <div class="d-td" >№</div>
                <div class="d-td" >Название</div>
                <div class="d-td" >Тип аккаунта</div>
                <div class="d-td" >Всего</div>
                <div class="d-td" >Последний пак</div>
                <div class="d-td" >Остаток</div>
                <div class="d-td" >Использовано</div>
                <div class="d-td" >Добавить пак</div>
            </div>
            <div class="d-tr  " v-for="(obj, i) in dict"  :key="i">
                    <div class="d-td" >{{getCount(i+1)}}</div>
                  <div class="d-td"  > <a >{{obj.name}}</a> </div>
                  <div class="d-td"  >{{getTypeName(obj.type)}}</div>
                  <div class="d-td"  > <a href="" @click="openAccount(obj.id)">{{obj.all_reports_count}}</a> </div>
                  <div class="d-td"  >{{obj.last_value}}</div>
                  <div class="d-td"  >{{obj.report_balance}}</div>
                  <div class="d-td"  >{{obj.created_reports_count}}</div>
                  <div v-if="editObject == null || editObject.id != obj.id" class="d-td"  >
                      <button class=" btn butDef" @click="toChange(obj)" :disabled="user.account.balance < 1 ">
                          Добавить
                      </button>
                  </div>
                  <div v-if="editObject != null && editObject.id == obj.id" class="d-td"  >
                      <div class="">
                        <input v-model="packet" type="number"/>
                        <button @click="plusPack(editObject)"><i class="fas fa-plus"></i></button>
                      </div>
                      
                  </div>
                </div>
                  
                  
            </div>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
export default {
   data () {
      return {
        packet: '',
        size:20,
        selected:[],
        toWhom: '',
        filterInput: '',
        message: {},
        editObject: null,
      }
    },
    computed: {
      ...Vuex.mapGetters({
            user: 'auth/user',
            accounts:'accounts/accounts',
            pageNumber:'accounts/pageNumber',
            pagination:'accounts/pagination',
      }),
      pageCount(){
        var l = this.pagination.total
        var s = this.pagination.limit
        return Math.ceil(l/s)
      },
      dict(){
         if(this.filterInput != '' )
            {
              
                return this.findBy( this.filterInput) 

            }else{
                return this.accounts
            }
      },
    },
    watch:{
        pageNumber(){
            this.getAccounts({page:this.pageNumber})
        }
    },
    methods: {
        ...Vuex.mapActions({
            getAccounts:'accounts/getAccounts',
            addPack:'accounts/addPack',
        }),
        ...Vuex.mapMutations({
            setPageNumber:'accounts/setPageNumber',
        }),
        openAccount(id){
            this.$router.push('/view/' +  id)
        },
        getTypeName(val){
            var name = ''
            if(val == 30){
                name = "Клиент"
            }
            if(val == 20){
                name = "Дистрибьютор"
            }
            return name
        },
        getCount(val){
            val + +1
            return val
        },
        nextPage(){
            var val = this.pageNumber
            val ++ 
            this.setPageNumber(val)
        },
        prevPage(){
            var val = this.pageNumber
            val --
             this.setPageNumber(val)
        },
        addClient(){
            this.$router.push('/addclient')
        },
        exit(){
           this.$router.push('/logout') 
        },
        toChange(object){
            this.editObject = Object.assign({}, object)
        },
        plusPack(editObject){
            this.addPack({buyer_id:editObject.id,value:this.packet })
            .then(()=>{
                this.getAccounts({page:this.pageNumber})
                this.editObject = null
            })
        },
        findBy(search) {
            let result = this.accounts
            if(search) {
                result = result.filter(item => {
                    if(item.name.toLowerCase().indexOf(search) != -1 ) {
                        return item
                        
                    }
                })
            }
            return result
        },

    },
    created(){
        this.getAccounts({page:this.pageNumber})
    }
}
</script>

<style >
.main__heading{
    margin: 0 auto;
    text-align: center;
}
.d-table{
    display: table;
    width: 100%;
    border-collapse: collapse;
    height: 60px;
    cursor: pointer;
    user-select: none; 
    
  }
  .d-tr{
    display: table-row;
    /* border: none; */
    height: 40px;
    border: 1px solid #ccc;
  }
  .d-table :hover{
    /* background-color: #3f4654; */
    /* color:white; */
  }
  .d-td{
    display: table-cell;
    text-align: center;
    /* border: none; */
    vertical-align: middle;
    border: 1px solid #ccc;
  }
  .d-td:not(.no-p){
    padding: 4px;
  }
  .acc__block{
      margin-top: 5px;
      margin-bottom: 5px;
  }
  .butDef{
    background-color: #5ad2cc;
    border: 0 solid #5ad2cc;
  }

</style>