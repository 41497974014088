/**
* Created by vouill on 11/13/17.
*/

<template>
  <div class="login_form">
    <div class="container h100">
      <div class="col-md-auto">
          <div class="sticky col-6 offset-3">
        <handler-errors :errorMes="errorMes"/>
      </div>
      <div class="row justify-content-md-center">
        
          <section id="s1" class="login_page"  style="margin-top:30%">
            <div class="inner">
              <div class="wrap">
                <img src="/img/logo_techcon.png" alt="">
                <h1>Активация пароля </h1>
                <div v-if="message" class="alert alert-danger">
                    {{ message }}
                </div>
                <form action="" class="login" @submit.prevent="activatePass">
                  <h4>Пароль</h4>
                  <input required v-model="activ.password" id="password" type="password" placeholder="Введите пароль" >
                  <h4>Ваш пароль</h4>
                  <input required v-model="activ.confirm" id = "password"  type="password" placeholder="Введите подтверждение пароля">
                  <br>

                  <input type="submit" id="submit" value="Подтвердить">
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HandlerErrors from '../../utils/handlerErrors.vue'
  import { mapGetters } from 'vuex'
  import Vuex from 'vuex'

  export default {
    name: 'auth',
    data () {
      return {
        errorMes: '',
        activ:{
            confirm: "",
            key: "",
            password: ""
            }
      }
    },
    components: {
      // Buttons,
                HandlerErrors},
    computed: {
      ...mapGetters(['isAuthenticated']),
      message () {
        return this.$store.state.auth.message
      },
    },
    methods: {
        ...Vuex.mapActions({
            activate:'auth/activate',
        }),
      activatePass () {
          this.activ.key = this.$route.params.key
          this.activate(this.activ)
            .then((resp)=>{
                this.$router.push('/login') 
                this.errorMes = resp
            })
             .catch((error) => {
                this.errorMes = error.response.data.error.detail[0]
          })
      }
    },
  }
</script>
<style scoped>
 .h100{
   min-height: 100vh;
 }
</style>
