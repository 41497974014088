<template>
    <div>
      <h1>Детализация</h1>
        <table class="d-table">
           <thead class="table__heading">
             <th class="d-td" >Количество</th>
            <th class="d-td" >Продал</th>
            <th class="d-td" >Купил</th>
            <th class="d-td" >Пользователь</th>
            <th class="d-td" >Создано</th>
           </thead>
           <tbody v-for="obj in billing " :key="obj.id">
             <td  class="d-td" >{{obj.value}}</td>
              <td  class="d-td" >{{obj.sellerAccount.name}}</td>
              <td  class="d-td" >{{obj.buyerAccount.name}}</td>
              <td  class="d-td" >{{obj.sellerUser.surname}} {{obj.sellerUser.name}} {{obj.sellerUser.middlename}}</td>
              <td  class="d-td" >{{getDate(obj.createdAt)}}</td>
           </tbody>
        </table>
    </div>
</template>

<script>

import Vuex from 'vuex'

  export default {
    data () {
      return {
      }
    },
    computed: {
      ...Vuex.mapGetters({
          billing: 'accounts/billing',
        }),
    },
    methods: {
      ...Vuex.mapActions({
          getBillingDetail: 'accounts/getBillingDetail',
      }),
      ...Vuex.mapMutations({
      }),
      getDate(timestamp){
        var nd = new Date(timestamp*1000)
          var options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',

                }
             var date = new Date(nd)
            var d = date.toLocaleString("ru", options)
            var d1 = new Date();
            var d2 = new Date(d1);
        return d
      },
    },
    created () {
        this.getBillingDetail({byerID: + this.$route.params.id})
    },
  }
</script>