import Http from '../../utils/http'
import Config from '../../utils/config'

const state = {
  message: null,
  isAuthenticated: null,
  user: {},
}

const getters = {
  message: state => state.message,
  isAuthenticated: state => state.isAuthenticated,
  user: state => state.user
}

const actions = {
  auth: ({commit, dispatch}, LoginForm) => {
    return new Promise((resolve) => {
      Http.post(Config.auth + 'v1/auth', LoginForm)
        .then(resp => {
          commit('setMessage', null)
          commit('setIsAuthenticated', true)
          dispatch('check')
          resolve(resp)
        })
        .catch(() => {
          commit('setMessage', 'Неверный логин и пароль')
          commit('setIsAuthenticated', false)
        })
    })
  },
  activate: ({commit}, form) => {
    return new Promise((resolve,reject) => {
      Http.post(Config.auth + 'v1/auth/activate', form)
        .then(resp => {
          commit('setMessage', null)
          resolve(resp)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  check: ({commit}) => {
    return new Promise((resolve, reject) => {
      Http.get(Config.auth + 'v1/check')
        .then(resp => {
          commit('setIsAuthenticated', true)
          commit('setUser', resp.data)
          resolve(resp)
        })
        .catch(err => {
          if (err.response) {
            commit('setIsAuthenticated', false)
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('error')
          }
        })
    })
  },
  logout: ({commit, dispatch}) => {
    return new Promise((resolve) => {
      Http.get(Config.auth + 'v1/logout', {withCredentials: true})
        .then(resp => {
          commit('setIsAuthenticated', false)
          dispatch('check')
          resolve(resp)
        })
    })
  },
}

const mutations = {
  setMessage: (state, message) => {
    state.message = message
  },
  setIsAuthenticated: (state, isAuthenticated) => {
    state.isAuthenticated = isAuthenticated
  },
  setUser: (state, user) => {
    state.user = user
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
