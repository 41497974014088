import Vue from 'vue'
import Router from 'vue-router'
import Home from '../components/main'
import Addclient from '../components/addClient'
import Login from '../components/auth/login'
import Logout from '../components/auth/logout'
import Activate from '../components/auth/activate'
import ViewAccount from '../components/viewAccount'
import store from '../store'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated !== 'true') {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated === 'true') {
    next()
    return
  }
  next('/login')
}

const check = (to, from, next) => {
  store.dispatch('auth/check')
    .then(() => {
      next()
    })
    .catch(() => {
      next('/login')
    })
  next()
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      beforeEnter: check, ifAuthenticated,
    },
    {
      path: '/view/:id',
      name: 'ViewAccount',
      component: ViewAccount,
      beforeEnter: check, ifAuthenticated,
    },
    {
      path: '/addclient',
      name: 'Addclient',
      component: Addclient,
      beforeEnter: check, ifAuthenticated,
    },
    
    {
      path: '/login',
      name: 'Login',
      component: Login,
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout,
    },
    {
      path: '/activate/:key',
      name: 'Activate',
      component: Activate,
    },
  ],
})
