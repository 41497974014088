
<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal"
      >
        <section
          class="modal-body"
          id="modalDescription"
        >
          <slot name="body">
              <h4 class="alert__data">
                   {{mesData}}
              </h4>
              <h4 class="alert__data">

                {{mesCompany}}
              </h4>
              
          </slot>
        </section>
        <footer class="modal-footer">
          <slot name="footer">
            <button
              type="button"
              class="btn-green"
              aria-label="Close modal"
              @click="close()"
            >
               Ок
            </button>
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import Vuex from 'vuex'
  export default {
    data () {
      return {
      }
    },
    computed: {
        ...Vuex.mapGetters({
            messageAddData:'accounts/messageAddData',
            messageAddCompany:'accounts/messageAddCompany',
        }),
        mesData(){
            var value = ''
            if(this.messageAddData.statusCode == 200){
                value = "Данные успешно добавлены"
            }else{
                  value = this.messageAddData.statusCode + "/" + this.messageAddData.message
            }
            return value
        },
        mesCompany(){
            var value = ''
            if(this.messageAddCompany.statusCode == 200){
                value = "Компания успешно добавлена"
            }else{
                  value = this.messageAddCompany.statusCode + "/" + this.messageAddCompany.message
            }
            return value
        },
    },
     methods: {
        ...Vuex.mapMutations({
          setVisibleModalMes:'accounts/setVisibleModalMes'
      }),
      close() {
        this.setVisibleModalMes(false)
        this.$router.push('/')
        
      },
    },
  }
</script>


<style>
  .modal-backdrop {
    min-width: 100px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    display: flex;
    min-width: 600px;
    max-width: 600px;
    min-height: 300px;
    max-height: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    border-bottom: 1px solid #222C40;
    color: #222C40;
    justify-content: space-between;
    
  }

  .modal-footer {
    /* border-top: 1px solid #222C40; */
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    /* width: 800px;
    height: 400px; */
  }
  .alert__data{
    padding: auto;   
  }
  .btn-green{
      background-color: #5ad2cc;
    border: 0 solid #5ad2cc;
  }

</style>