<template>
    <div class="alert alert-danger  " v-if="errorToString != ''">
        {{errorToString}}
    </div>
</template>

<script>
import Error from './errors'
export default {
    name: 'handlerErrors',
    props:['errorMes',
    ],
  computed: {
    errorToString () {
      return Error.methods.toString(this.errorMes)
    }
  }
}
</script>
