import Http from '../../../utils/http'
import Config from '../../../utils/config'

const state = {
  oneAccount: {},
  maessage: '',
  accounts: [],
  pageNumber: 1,
  pagination: {},
  messageAddCompany: '',
  messageAddData: '',
  visibleModalMes: false,
  billing: [],
}

const getters = {
  messageAddCompany: state => state.messageAddCompany,
  messageAddData: state => state.messageAddData,
  maessage: state => state.maessage,
  accounts: state => state.accounts,
  pageNumber: state => state.pageNumber,
  pagination: state => state.pagination,

  visibleModalMes: state => state.visibleModalMes,
  oneAccount: state => state.oneAccount,
  billing: state => state.billing,
}

const mutations = {
  setMessageAddCompany: (state, messageAddCompany) => {
    state.messageAddCompany = messageAddCompany
  },
  setMessageAddData: (state, messageAddData) => {
    state.messageAddData = messageAddData
  },

  setMessage: (state, maessage) => {
    state.maessage = maessage
  },
  setAccounts: (state, accounts) => {
      state.accounts = accounts
  },
  setPageNumber: (state, pageNumber) => {
    state.pageNumber = pageNumber
  },
  setPagination: (state, pagination) => {
    state.pagination = pagination
  },
  setVisibleModalMes: (state, visibleModalMes) => {
    state.visibleModalMes = visibleModalMes
  },
  setOneAccount: (state, oneAccount) => {
    state.oneAccount = oneAccount
  },
  setBilling: (state, billing) => {
    state.billing = billing
  },
}


const actions = {
  getAccounts: ({commit}, form) => {
    return new Promise((resolve,) => {
      Http.post(Config.auth + 'v1/account/search', form)
        .then(resp => {
          commit('setAccounts', resp.data)
          commit('setPagination', resp.pagination)
          resolve(resp)
        })
        .catch(() => {
        })
    })
  },
  addCompany: ({commit}, form) => {
    return new Promise((resolve,reject) => {
      Http.post(Config.auth + 'v1/account/createaccount', form)
        .then(resp => {
          commit('setMessageAddCompany', resp.data.addCompany)
          commit('setMessageAddData', resp.data.copyData)
          resolve(resp)
        })
        .catch(error => {
          // console.log(error)\
          reject(error)
        })
    })
  },
  addPack: ({commit}, form) => {
    return new Promise((resolve) => {
      Http.post(Config.apiUrl + 'billing/addbill', form)
        .then(resp => {
          commit('setMessage', resp.message)
          resolve(resp)
        })
        .catch(error => {
        })
    })
  },
  getOneAccount: ({commit}, id) => {
    return new Promise((resolve,) => {
      Http.get(Config.auth + 'v1/account/' + id)
        .then(resp => {
          commit('setOneAccount', resp.data)
          resolve(resp)
        })
        .catch(() => {
        })
    })
  },
  getBillingDetail: ({commit}, form) => {
    return new Promise((resolve,) => {
      Http.post(Config.auth + 'v1/billing/detail', form)
        .then(resp => {
          commit('setBilling', resp.data)
          resolve(resp)
        })
        .catch(() => {
        })
    })
  },
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
